







import Form from '@/components/organisms/Purchase/Contract/Change/SelectForm.vue'
import menuStore from '@/store/Menu'
import organizationGroupStore from '@/store/OrganizationGroups'
import organizationStore from '@/store/Organizations'
import planStore from '@/store/Plans'
import contractStore, { Contract, ContractType } from '@/store/Contracts'
import { Component, Vue } from 'vue-property-decorator'
import StepBar from '@/components/organisms/Purchase/Contract/Change/StepBar.vue'

@Component({ components: { Form, StepBar } })
export default class extends Vue {
  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })
    try {
      await Promise.all([
        organizationStore.fetchCurrentOrganization(),
        contractStore.fetchLatestContract(),
        contractStore.fetchFutureContract(),
        organizationGroupStore.fetchCount(),
        planStore.fetchPlans(),
      ])

      if (!this.isPossibleChangeContract(contractStore.latestContract, contractStore.futureContract)) {
        throw new Error()
      }
    } catch {
      this.$router.replace({ name: 'Contract' })
    }
  }

  /**
   * 現在がクレジット契約で解約済みでは無いかつ、翌月以降が相対ではない\
   * または、翌月以降がクレジット契約\
   */
  isPossibleChangeContract(latestContract: Contract | null, futureContract: Contract | null): boolean {
    return (
      (this.isCredit(latestContract) && !this.isCanceled(latestContract) && !this.isNegotiation(futureContract)) ||
      this.isCredit(futureContract)
    )
  }

  isCredit(contract: Contract | null): boolean {
    return contract?.type === ContractType.CREDIT
  }

  isNegotiation(contract: Contract | null): boolean {
    return contract?.type === ContractType.NEGOTIATION
  }

  isCanceled(contract: Contract | null): boolean {
    return !!contract?.endDate
  }

  contractExists(contract: Contract | null): boolean {
    return JSON.stringify(contract) !== '{}'
  }
}
