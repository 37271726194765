import config from '@/config'
import store from '@/store'
import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export const ErrorMsg = {
  ResponseError: (status: number) => 'API response error status:' + status,
  FetchPlanFailureError: 'プラン取得に失敗しました',
} as const

export type Plan = {
  id: number
  itemId: string
  name: string
  price: number
  maxGroups: number | null
}

@Module({ dynamic: true, namespaced: true, name: 'plans', store })
class Mod extends VuexModule {
  plans: Plan[] = []

  @Mutation
  setPlans(plans: Plan[]): void {
    this.plans = plans
  }

  @Action({ commit: 'setPlans', rawError: true })
  async fetchPlans(): Promise<Plan[]> {
    const url = `${config.enterprise.apiUrl}/no-contract/plans`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchPlanFailureError)
    }
  }
}

export default getModule(Mod)
