














































































import Spinner from '@/components/atoms/Spinner.vue'
import config from '@/config'
import contractStore, { Contract } from '@/store/Contracts'
import organizationGroupStore from '@/store/OrganizationGroups'
import organizationStore, { Organization } from '@/store/Organizations'
import planStore, { Plan } from '@/store/Plans'
import { Sbp, TAX_RATE } from '@/store/Purchase'
import dayjs from '@/libs/dayjs'
import { v4 as uuidv4 } from 'uuid'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { Spinner },
})
export default class extends Vue {
  form: HTMLFormElement | null = null
  selectedPlan: Plan | null = null
  selectedPayMethod = 'credit'
  hashSource = ''
  isLoading = false
  error = ''

  sbp = {
    merchantId: config.purchase.sbp.merchantId,
    serviceId: config.purchase.sbp.serviceId,
    payType: '1', //継続課金（簡易）
    autoChargeType: '1', //自動課金する
    serviceType: '0', //売上（購入）
    divSettele: '0', //前払い
    terminalType: '0', //PC
    successUrl: config.purchase.sbp.change.successUrl,
    cancelUrl: config.purchase.sbp.change.cancelUrl,
    errorUrl: config.purchase.sbp.change.errorUrl,
    pageconUrl: config.purchase.sbp.change.createPageconUrl,
  }

  mounted(): void {
    this.form = document.forms.namedItem('purchaseForm')
  }

  onSelectChange(): void {
    if (this.cancelTargetContract?.plan?.itemId === this.selectedPlan?.itemId) {
      this.error = '選択中のプランは現在ご契約中のプランです。変更するプランを選択してください'
    } else {
      this.error = ''
    }
  }

  get plans(): Plan[] {
    return planStore.plans.filter((plan) => plan.price > 0)
  }

  get currentOrganization(): Organization | null {
    return organizationStore.currentOrganization
  }

  get latestContract(): Contract | null {
    return contractStore.latestContract
  }

  get futureContract(): Contract | null {
    return contractStore.futureContract
  }

  get isLatestTrial(): boolean {
    return this.latestContract?.plan?.price === 0
  }

  get cancelTargetContract(): Contract | null {
    return this.futureContract ?? this.latestContract
  }

  get orgGroupCount(): number {
    return organizationGroupStore.count
  }

  get getSelectedPayMethod(): string {
    return this.selectedPayMethod
  }

  get getSelectedPlan(): Plan | null {
    return this.selectedPlan
  }

  get maxGroups(): number | null {
    const latestMaxGroups = this.latestContract?.plan?.maxGroups
    if (latestMaxGroups === undefined) {
      return 0
    }

    const futureMaxGroups = this.futureContract?.plan?.maxGroups
    if (futureMaxGroups === undefined) {
      return latestMaxGroups
    }

    if (latestMaxGroups === null || futureMaxGroups === null) {
      return null
    } else if (latestMaxGroups >= futureMaxGroups) {
      return latestMaxGroups
    } else {
      return futureMaxGroups
    }
  }

  get canConfirm(): boolean {
    return !!this.selectedPlan && this.cancelTargetContract?.plan?.itemId !== this.selectedPlan.itemId
  }

  back(): void {
    this.$router.push({
      name: 'Contract',
    })
  }

  async goToPayment(): Promise<void> {
    this.isLoading = true

    if (!this.isValidMaxGroups()) {
      this.error = '上限グループ数が現在のグループ数未満です。'
      this.isLoading = false
      return
    }

    try {
      if (this.form === null) {
        throw 'formが設定されていません。'
      }

      if (this.setRequestParams() === null) {
        throw '購入要求パラメータの設定に失敗しました。'
      }

      sessionStorage.setItem(
        'ContractChangeInfo',
        JSON.stringify({
          pay_method: this.form.pay_method.value,
          merchant_id: this.form.merchant_id.value,
          service_id: this.form.service_id.value,
          cust_code: this.form.cust_code.value,
          order_id: this.form.order_id.value,
          item_id: this.form.item_id.value,
          item_name: this.form.item_name.value,
          tax: this.form.tax.value,
          amount: this.form.amount.value,
          pay_type: this.form.pay_type.value,
          auto_charge_type: this.form.auto_charge_type.value,
          service_type: this.form.service_type.value,
          div_settele: this.form.div_settele.value,
          camp_type: this.form.camp_type.value,
          terminal_type: this.form.terminal_type.value,
          success_url: this.form.success_url.value,
          cancel_url: this.form.cancel_url.value,
          error_url: this.form.error_url.value,
          pagecon_url: this.form.pagecon_url.value,
          request_date: this.form.request_date.value,
          maxGroups: this.getSelectedPlan?.maxGroups,
        })
      )
      this.$router.push({ name: 'PurchaseContractChangeChangeCancel' })
    } catch (e) {
      this.error = '購入処理に失敗しました。'
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  setRequestParams(): void | null {
    if (this.form === null) {
      return null
    }

    const campType = this.getCampType()
    if (campType === null) {
      return null
    }
    this.form.camp_type.value = campType

    const tax = this.culculateTax()
    if (tax === null) {
      return null
    }
    this.form.tax.value = tax

    const amount = this.culculateAmount()
    if (amount === null) {
      return null
    }
    this.form.amount.value = amount

    this.form.pay_method.value = this.getSelectedPayMethod
    this.form.item_id.value = this.getSelectedPlan?.itemId
    this.form.item_name.value = this.getSelectedPlan?.name
    this.form.cust_code.value = this.currentOrganization?.id
    this.form.order_id.value = this.generateContractId()
    this.form.request_date.value = dayjs().format('YYYYMMDDHHmmss')
  }

  formatPrice(price: number): string {
    return price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
  }

  culculatePriceIncludingTax(price: number): number {
    return price + price * TAX_RATE
  }

  culculateAmount(): number | null {
    const price = this.selectedPlan?.price
    if (price === undefined) {
      return null
    }
    const tax = this.culculateTax()
    if (tax === null) {
      return null
    }
    return price + tax
  }

  culculateTax(): number | null {
    const price = this.selectedPlan?.price
    if (price === undefined) {
      return null
    }
    return price * TAX_RATE
  }

  getCampType(): number | null {
    if (this.latestContract === null) {
      return null
    }

    if (JSON.stringify(this.latestContract) === '{}') {
      return Sbp.CampType.WITH_PAYMENT_THIS_MONTH
    } else if (
      this.latestContract.endDate === null ||
      dayjs(this.latestContract.endDate).isSame(dayjs().endOf('month'), 'days')
    ) {
      return Sbp.CampType.WITHOUT_PAYMENT_THIS_MONTH
    } else if (this.isLatestTrial && dayjs(this.latestContract.endDate).isAfter(dayjs(), 'months')) {
      return Sbp.CampType.WITHOUT_PAYMENT_THIS_MONTH
    }

    return Sbp.CampType.WITH_PAYMENT_THIS_MONTH
  }

  generateContractId(): string {
    return uuidv4()
  }

  isValidMaxGroups(): boolean {
    if (this.maxGroups === null) {
      return false
    }
    if (this.orgGroupCount > this.maxGroups) {
      return false
    }
    return true
  }
}
